<style lang="scss" scoped>
.el-input {
  width: 400px;
}
</style>
<template>
  <el-form
    size="small"
    :inline="true"
    :ref="(el) => (state.formInline = el)"
    label-width="100px"
    :model="state.form"
    class="demo-form flex_tb"
    :rules="state.addRules"
  >
    <el-form-item label="原密碼" prop="name">
      <el-input
        placeholder="請輸入原密碼"
        v-model="state.form.name"
        size="medium"
        type="password"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="密碼" prop="email">
      <el-input
        placeholder="請輸入密碼"
        v-model="state.form.email"
        size="medium"
        type="password"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="確定密碼" prop="phone">
      <el-input
        placeholder="確定密碼"
        v-model="state.form.phone"
        size="medium"
        type="password"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label=" ">
      <el-button type="primary" @click="seach" size="medium">確定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";

export default {
  setup(props, { emit }) {
    const validatePass = (rule, value, callback) => {
      // 密碼正則校驗
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(value)) {
        callback(new Error("密碼至少包含數字和英文,6-18個字符"));
      } else {
        callback();
      }
    };

    const isPass = (rule, value, callback) => {
      // 密碼正則校驗
      if (state.form.email !== state.form.phone) {
        callback(new Error("兩次輸入密碼不一致"));
      } else {
        callback();
      }
    };

    const state = reactive({
      formInline: "",
      form: {
        name: "",
        phone: "",
        email: "",
      },
      addRules: {
        name: [
          { required: true, message: "請輸入原密碼", trigger: "blur" },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "請再次輸入密碼", trigger: "blur" },
          {
            validator: isPass,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
    });

    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $utils } = proxy;

    const reset = () => {
      //   $http
      //     .getAdminInfo({
      //       TOKEN: $utils.getCookie("token"),
      //     })
      //     .then((res) => {
      //       state.form.name = res.data.username;
      //       state.form.phone = res.data.phone;
      //       state.form.email = res.data.email;
      //     });
    };
    const resetValue = () => {
      // 重置
      state.form = {
        seatch_name: "",
        region: "",
      };
    };

    reset();

    const seach = () => {
      state.formInline.validate(async (valid) => {
        if (!valid) return;
        const res = await $http.updPass({
          oldPassword: state.form.name,
          password: state.form.phone,
        });
        if (res.status !== 200) return;
        $message.success("修改成功");
        reset();
      });
    };

    return {
      state,
      resetValue,
      seach,
    };
  },
};
</script>
